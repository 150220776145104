// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/isPreview.jsx"
);
import.meta.hot.lastModified = "1721025454795.2642";
}
// REMIX HMR END

const isServer = typeof window === "undefined";
const isPreview = () => {
  let isPreviewValue = isServer
    ? process.env.STORYBLOK_IS_PREVIEW
    : window.env.STORYBLOK_IS_PREVIEW;

  return isPreviewValue === "yes";
};
export { isPreview };
